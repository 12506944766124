import {Button, Col, Modal, QRCode, Row} from "antd";
import React, {useEffect, useState} from "react";

export default function QrModal({isModalOpenned, setIsModalOpenned}:{isModalOpenned:boolean,
    setIsModalOpenned:React.Dispatch<React.SetStateAction<boolean>>}){
    const [qrValue, setQrValue] = useState("www.google.es"); // Valor inicial del código QR

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (isModalOpenned) {
            intervalId = setInterval(() => {
                const min = 1;
                const max = 10000;
                const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                const newQrValue = randomNumber.toString();
                setQrValue(newQrValue);
            }, 15000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isModalOpenned]);
    return (
        <Modal title={<h2>Código QR</h2>} open={isModalOpenned} onCancel={()=>setIsModalOpenned(false)}
               width={500}
               footer={[
                   <Button key="cancel" onClick={()=>setIsModalOpenned(false)} style={{backgroundColor: 'grey', color: 'white'}}>
                       Cerrar
                   </Button>
               ]}
        >
            <Row justify={'center'}>
                <Col>
                    <QRCode size={300} value={qrValue} />
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col>
                    <p style={{color:'grey'}}>Este código QR dejará de ser valido en 15 segundos</p>
                </Col>
            </Row>
        </Modal>
    );
}