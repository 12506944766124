import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {ITicket} from "../../solutions/interfaces/ITicket";
import {Button,  Col,  message,  Row, Table} from "antd";
import {onSearch} from "../../solutions/controllers/CollectionController";

import Search from "antd/es/input/Search";
import ModalChangeTicketName from "../ModalChangeTicketName/ModalChangeTicketName";
import QrModal from "../qrModal/QrModal";
import {formatDateToString} from "../../solutions/Utils";

interface TicketsTableProps {
    tickets: any[];
}
export function TicketsTable(props: TicketsTableProps){
    const [ticketsTable, setTicketsTable] = useState<DataType[]>([]);
    const [filteredData, setFilteredData] = useState<DataType[]>([]);
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedTickets, setSelectedTickets] = useState<DataType[]>();
    const [isQrModalVisible, setIsQrModalVisible] = useState(false);

    interface DataType {
        key: number;
        title: string;
        pack:string;
        date: Date;
        ticketType: string;
        sitNumber:string;
        used:string;
        contentPack:string[]
        status:string;
    }

    function openQrModal() {
        setIsQrModalVisible(true);
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'NOMBRE FESTIVAL',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'ACCIONES',
            key: 'action',
            render: (_, record) => (
                record.status === 'NOT_USED' ? <Button type="text" onClick={openQrModal} >CANJEAR ENTRADA</Button> : null
            ),
        },
        {
            title: 'FECHA',
            dataIndex: 'date',
            key: 'date',
            render: (date: Date) => (
                formatDateToString(date)
            ),
        },
        {
            title: 'TIPO DE ENTRADA',
            dataIndex: 'ticketName',
            key: 'ticketName',
        },
        {
            title: 'NÚMERO ENTRADA/ASIENTO',
            dataIndex: 'sitNumber',
            key: 'sitNumber',
        },
        {
            title: 'ESTADO',
            dataIndex: 'status',
            key: 'status',
        },

    ];

    useEffect(()=>{
        if (props.tickets){
            let newData: DataType[] = props.tickets.map((ticket) => ({
                key: ticket.ticketsoldid,
                ...ticket
            }));
            setTicketsTable(newData);
        }
    },[props.tickets])

    useEffect(()=>{
        if (ticketsTable){
            setFilteredData(ticketsTable);
        }
    },[ticketsTable])

    useEffect(()=>{
        console.log(ticketsTable)
    },[ticketsTable])

    function handleSearch(value:string){
        if (ticketsTable && filteredData){
            onSearch(value,ticketsTable, filteredData,setSearchText,setFilteredData);
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    function handleChangeName() {
        if (selectedRowKeys.length>0){
            const selectedTickets = ticketsTable.filter((record) =>
                selectedRowKeys.includes(record.key)
            );
            setSelectedTickets(selectedTickets);
        }else{
            message.warning('Seleccione ticket(s)');
        }
    }

    // @ts-ignore
    return (
        <>
            {ticketsTable?(
                <>
                    {selectedTickets?(
                        <ModalChangeTicketName selectedTickets={selectedTickets}></ModalChangeTicketName>
                    ):''}
                    <QrModal isModalOpenned={isQrModalVisible} setIsModalOpenned={setIsQrModalVisible}></QrModal>
                    <Row style={{marginTop:10}}>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:10}} xl={{span:10}} xxl={{span:8}}>
                            <Search
                                placeholder="Buscar"
                                value={searchText}
                                style={{marginBottom:5, width:'100%'}}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Col>
                        <Col xs={{span:12, offset:0}} sm={{span:12, offset:0}} md={{span:12, offset:0}} lg={{span:7, offset:0}} xl={{span:5, offset:0}} xxl={{span:3, offset:0}}>
                            <Button  onClick={()=>handleSearch('')} style={{ width:'100%'}}>Limpiar busqueda</Button>
                        </Col>
                        <Col xs={{span:12, offset:0}} sm={{span:12, offset:0}} md={{span:12, offset:0}} lg={{span:7, offset:0}} xl={{span:5, offset:0}} xxl={{span:3, offset:0}}>
                            <Button onClick={handleChangeName} style={{ width:'100%'}}>Cambio de nombre</Button>
                        </Col>

                    </Row>
                    <Table scroll={{ x: true }} style={{width:'100%', marginTop:10}} rowSelection={rowSelection} columns={columns} dataSource={filteredData}/>
                </>
            ):''}
        </>
    );
}