import {getFormattedFieldValue, getKindOfFieldByTypeName} from "../forms/FormsController";

export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, source) => {
    if (target == null && source == null) return null;
    let output = Object.assign({}, target);
    if ((isObject(target) || target == null) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target)) {
                    Object.assign(output, {[key]: source[key]});
                } else {
                    output[key] = mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(output, {[key]: source[key]});
            }
        });
    }
    return output;
}

export const deepClone = aObject => {
    if (!aObject) {
        return aObject;
    }
    let bObject, v, k;
    bObject = Array.isArray(aObject) ? [] : {};
    for (k in aObject) {
        v = aObject[k];
        bObject[k] = (typeof v === "object") ? deepClone(v) : v;
    }
    return bObject;
}

export const deepRemoveField = (aObject, fieldName, alreadyProcessed = []) => {
    if (aObject != null) {
        if (aObject[fieldName]) {
            delete aObject[fieldName];
        }
        alreadyProcessed.push(aObject);
        let k, v;
        for (k in aObject) {
            v = aObject[k];
            if (typeof v === "object" && alreadyProcessed.indexOf(v) == -1) {
                deepRemoveField(v, fieldName, alreadyProcessed);
            }
        }
    }
}

export const isEntity = (typeName) => {
    if (typeName) {
        const nonEntities = ['int', 'double', 'float', 'boolean', 'char'];
        const nonEntityType = typeName.startsWith("java") || nonEntities.indexOf(typeName) != -1;
        return !nonEntityType;
    } else {
        return false;
    }
}

export const getClassNameFromTypeName = (typeName) => {
    if (typeName) {
        const parts = typeName.split(".");
        if (parts.length > 0) {
            return parts[parts.length - 1].toLowerCase();
        }
    }
    return typeName;
}

const getPropertyFromObject = function (object, key) {
    const separatorPosition = key.indexOf(".");
    if (separatorPosition == -1) {
        return object[key];
    } else {
        const firstPartOfKey = key.substring(0, separatorPosition);
        const restOfKey = key.substring(separatorPosition + 1);
        return getPropertyFromObject(object[firstPartOfKey], restOfKey);
    }
};

export const matchWithTemplate = (object, template, ui) => {
    const allKeys = Object.keys(template);
    const properties = allKeys.reduce((l, c) => ({...l, [c]: getPropertyFromObject(object, c)}), {});
    return allKeys.every(key => {
        let textToFind, whereToFind;
        const property = properties[key];
        let fieldDefinition = (ui && ui.fields && ui.fields[key]) || (template[key] && template[key].fieldDefinition);
        if (isObject(template[key])) {
            if (template[key] == null || template[key].value == "") return true;
            if (Object.keys(template[key]).indexOf("value") == -1) {
                textToFind = getFormattedFieldValue(template[key], fieldDefinition).toLocaleLowerCase();
            } else {
                textToFind = template[key].value;
            }
            whereToFind = getFormattedFieldValue(property, fieldDefinition).toLocaleLowerCase()
        } else {
            if (template[key] == null || template[key] == "") return true;
            textToFind = template[key];
            if (isObject(property)) {
                whereToFind = getFormattedFieldValue(property, fieldDefinition).toLowerCase();
            } else if (typeof property == "boolean") {
                whereToFind = property === true ? "S" : (property === false ? "N" : "");
            } else {
                whereToFind = (property + "").toLocaleLowerCase();
            }
        }
        if (property != null) {
            return whereToFind.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                .indexOf(textToFind.toLowerCase().normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, "")) != -1;
        }
        return Object.keys(object).indexOf(key) == -1;
    })
}

export const sumOfField = (arrayOfObjects, field, decimalPlaces = 0) => {
    const total = arrayOfObjects.reduce((l, r) => l + r[field], 0);
    return total.toLocaleString(undefined, {minimumFractionDigits: decimalPlaces});
}

export const getMaxKey = (object) => {
    let maxKey = null;
    if (object) {
        Object.keys(object).map((key) => {
            if (maxKey) {
                if (Number(key) > maxKey) {
                    maxKey = Number(key);
                }
            } else {
                maxKey = Number(key);
            }
        });
    }
    return maxKey;
}

export const getUiDefinition = (c, ui) => {
    let toReturn = null;
    if (ui) {
        const field = Object.keys(ui.fields).filter(k => ui.fields[k].name == c);
        if (field.length > 0) {
            toReturn = ui.fields[field[0]].typeName;
        }
    }
    return toReturn;
}

export const isADateField = (c, ui) => getUiDefinition(c, ui) == null ?
    false
    : getKindOfFieldByTypeName(getUiDefinition(c, ui)) === "date";

export const isANumberField = (c, ui) => getUiDefinition(c, ui) == null ?
    false
    : getKindOfFieldByTypeName(getUiDefinition(c, ui)) === "number";

export const getPersistenceCriteriaFromRecord = (record, ui) => {

    const isATextField = c => getUiDefinition(c, ui) == null ?
        typeof record[c] === "object" ? false : record[c].match && !record[c].match(/^-?\d+\.?\d*$/)
        : getKindOfFieldByTypeName(getUiDefinition(c, ui)) === "text";

    const LIKE = "OPERATOR_LIKE", EQUALS = "OPERATOR_EQUALS", BETWEEN = "OPERATOR_BETWEEN";

    const isAIntervalDate = (c, value) => isADateField(c, ui) && Array.isArray(value) && value.length === 2;

    const isAIntervalNumber = (c, value) => isANumberField(c, ui) && Array.isArray(value) && value.length === 2;

    const getFromInterval = value => value[0];

    const getToInterval = value => value[1];

    const criteriaItems = record ? Object.keys(record).reduce((l, c) => (record[c] != null ? [...l, {
        fieldName: c,
        comparisonOperator: isATextField(c) ? LIKE : isAIntervalDate(c, record[c]) || isAIntervalNumber(c, record[c]) ? BETWEEN : EQUALS,
        fieldValue: isATextField(c) ? "%" + record[c].trim() + "%" :
            isAIntervalDate(c, record[c]) || isAIntervalNumber(c, record[c]) ? getFromInterval(record[c]) : record[c],
        otherFieldValue: isAIntervalDate(c, record[c]) || isAIntervalNumber(c, record[c]) ? getToInterval(record[c]) : null
    }] : l), []) : [];
    return {criteriaItems: criteriaItems}
}

export const base64ToByteArray = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

export const binaryStringFromBinaryArray = binaryArray => {
    const string = new Uint8Array(binaryArray).reduce((data, byte) => data + String.fromCharCode(byte), '');
    return string;
}

export const base64FromArrayBuffer = arrayBuffer => {
    return btoa(binaryStringFromBinaryArray(arrayBuffer));
}
export const base64FromArrayBuffer2 = arrayBuffer => {
    return btoa(arrayBuffer);
}

export const getPhotoBytes = (arraybuffer, callback) => {
    if (arraybuffer) {
        const base64data = base64FromArrayBuffer(arraybuffer);
        return "data:image/png;base64," + base64data;
    } else {
        return null;
    }
}


export const getPhotoBytes2 = (arraybuffer, callback) => {
    if (arraybuffer) {
        const base64data = base64FromArrayBuffer2(arraybuffer);
        return "data:image/png;base64," + base64data;
    } else {
        return null;
    }
}
const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    const guidToReturn = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    return guidToReturn.substr(0, 16);
}

export const unique = (arr, comp) => {
    const unique = arr.map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
}

export const setNewPropsOnComponentState = (newProps, component, callback) => {
    const newState = Object.keys(newProps).filter(k => Object.keys(component.state).indexOf(k) != -1)
        .reduce((l, c) => ({
            ...l, [c]: newProps[c] != component.state[c] ? newProps[c] : "remove-this-key-from-newSTATE"
        }), {});
    const keysToDelete = Object.keys(newState).filter(k => newState[k] == "remove-this-key-from-newSTATE");
    keysToDelete.forEach(k => delete newState[k]);
    if (Object.keys(newState).length > 0) {
        component.setState(newState, callback);
    } else {
        callback && callback();
    }
}

export const logAndGetTick = (lastTick, message) => {
    const now = new Date().getTime();
    console.log(message + " in " + ((now - lastTick) / 1000.0) + " seconds");
    return now;
};

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}


export const fileToBytes = (file) => {
    return new Promise(function(resolve,reject){
        const filename = file.name;
        const asByteArrayReader = new FileReader();
        asByteArrayReader.onload = e => {
            resolve(new Uint8Array(Buffer.from(e.target.result).toJSON().data))
        };
        asByteArrayReader.onerror = (e) => {
            console.error("Error ",e)
            reject(asByteArrayReader);
        };
        asByteArrayReader.readAsArrayBuffer(file);
    });
};