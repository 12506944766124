import {Button, Checkbox, Col, DatePicker, Form, FormInstance, Input, message, Modal, Row} from "antd";
import ticketSelectedImage from "../../assets/ticket-selected.svg";
import React, {useEffect, useState} from "react";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {IUser} from "../../solutions/interfaces/IUser";
import {getProfile} from "../../solutions/controllers/UserController";
interface DataType {
    key: number;
    title: string;
    pack:string;
    date: Date;
    ticketType: string;
    sitNumber:string;
    used:string;
    contentPack:string[]
}
interface ModalChangeTicketNameProps {
    selectedTickets: DataType[];
}

export default function ModalChangeTicketName({selectedTickets, }: ModalChangeTicketNameProps){
    const [isModalOpenned,setIsModalOpenned] = useState(false);
    const [checkboxSelected,setCheckboxSelected] = useState(false);
    const [isRewardChecked,setIsRewardChecked] = useState(false);

    const [profile,setProfile] = useState<IUser>();
    const [form] = Form.useForm();
    const [keySelected, setKeySelected] = useState('');
    useEffect(()=>{
        if (selectedTickets){
            setIsModalOpenned(true);

        }
    },[selectedTickets])
    useEffect(()=>{
        if (checkboxSelected && keySelected!=='0' && profile){
            form.setFieldsValue({
                [`email-${keySelected}`]:profile.email,
            });
        }else if(!checkboxSelected && keySelected!=='0' && profile){
            form.setFieldsValue({
                [`email-${keySelected}`]:'',
            });
        }
    },[checkboxSelected, keySelected, profile])
    useEffect(()=>{
        if (isModalOpenned){
        }
    },[isModalOpenned])
    useEffect(()=>{
        //getProfile(setProfile)
    },[])
    function handlePostChangeName() {
        form.validateFields().then(()=>{
            setIsModalOpenned(false);
            message.success('Nombre(s) cambiado(s) correctamente');
        }).catch(()=>{
            message.error('Solucione los errores')
        })
    }

    const onChangeCheckbox = (e: CheckboxChangeEvent, key:string) => {
        setCheckboxSelected(e.target.checked);
        setKeySelected(key);
    };
    const onChangeRewardCheckbox = (e: CheckboxChangeEvent, key:string) => {
        setIsRewardChecked(e.target.checked);

    };
    return (
        <div>
            <Modal title="Asignación de tickets" open={isModalOpenned} onCancel={()=>setIsModalOpenned(false)}
                   width={1000}
                   footer={[

                       <Button  onClick={handlePostChangeName} key="buy"  style={{backgroundColor: '#45c4b0', color: 'white'}}>
                           0 € - Confirmar
                       </Button>
                       ,
                       <Button key="cancel" onClick={()=>setIsModalOpenned(false)} style={{backgroundColor: '#F64E60', color: 'white'}}>
                           Cerrar
                       </Button>
                   ]}
            >
                <Row>
                    <Col>
                        <h1>Datos de los asistentes</h1>
                    </Col>
                </Row>
                {// @ts-ignore
                    selectedTickets?selectedTickets.map((ticket) => (
                        <div key={ticket.key} style={{marginTop:15}}>
                            <Row style={{border:'1px solid grey'}}>
                                <Col span={1} offset={1} style={{marginTop:10}}>
                                    <img style={{width:'100%'}} src={ticketSelectedImage} alt={'ticketSelected'}></img>
                                </Col>
                                <Col span={12} offset={1}>
                                    <h3 style={{paddingTop:0, marginTop:10}}>{ticket.pack}</h3>
                                </Col>
                                <Col offset={1} span={8}>
                                    <Checkbox onChange={(e)=>onChangeCheckbox(e, ticket.key.toString())} style={{paddingTop:0, marginTop:10}}>
                                        Esta entrada es para mi
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row style={{border:'1px solid grey', borderTopWidth:0}}>
                                <Col offset={1} span={22}>
                                    <h4 style={{paddingTop:0, marginTop:10}}>
                                        {ticket.title}
                                    </h4>
                                </Col>
                                {ticket.contentPack.map((content=>{
                                    return (
                                        <Col offset={1} span={22}>
                                        <p style={{paddingTop:0, marginTop:10}}>{content}</p>
                                    </Col>
                                    )
                                }))}

                                <Col offset={1} span={22}>

                                    <Form
                                            form={form}
                                            key={ticket.key}
                                            name={ticket.key.toString()}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            style={{ width:'100%' }}
                                            initialValues={{ remember: true }}
                                            autoComplete="off"
                                        >
                                        <Form.Item label=""  name={`reward-${ticket.key.toString()}`}>
                                            <Checkbox onChange={(e)=>onChangeRewardCheckbox(e, ticket.key.toString())} style={{paddingTop:0, marginTop:10}}>
                                                Regalo
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item label="Fecha de envío" style={{marginBottom:5}} name={`sendingDate-${ticket.key.toString()}`}
                                                   rules={[{ required: true, message: 'Por favor introduzca la fecha de envío!' }]}>
                                            <DatePicker  placeholder={'Selecciona una fecha'} style={{border: '1px solid black',width: '100%'}}/>
                                        </Form.Item>
                                        <Form.Item
                                                style={{margin:0,padding:0, marginTop:5}}
                                                label="Nombre completo"
                                                name={`fullName-${ticket.key.toString()}`}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'Por favor introduzca su nombre completo!' }]}
                                            >
                                            <Input style={{border: '1px solid #d7f4ee', width: '100%'}}  placeholder={'Nombre completo'}/>
                                        </Form.Item>
                                        <Form.Item
                                                style={{margin:0,padding:0, marginTop:5}}
                                                label="DNI"
                                                name={`dni-${ticket.key.toString()}`}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'Por favor introduzca su DNI!' }]}
                                            >
                                            <Input style={{border: '1px solid #d7f4ee', width: '100%'}}  placeholder={'DNI'}/>
                                        </Form.Item>
                                        <Form.Item
                                                style={{margin:0,padding:0, marginTop:5, marginBottom:15}}
                                                label="Email del asistente"
                                                name={`email-${ticket.key.toString()}`}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'Por favor introduzca su email!' },{type:'email', message:'Email con formato inválido!'}]}
                                            >
                                            <Input style={{border: '1px solid #d7f4ee', width: '100%'}}  placeholder={'Email del asistente'}/>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    )):''}
            </Modal>
        </div>
    );
}