import {CONFIG, URLForKPIResource} from '../../index'
import RestClient from '../../rest/RestClient'

export const DASHBOARD_TIMEPERIODS_FETCHED = 'DASHBOARD_TIMEPERIODS_FETCHED'
export const DASHBOARD_FETCHED = 'DASHBOARD_FETCHED'
export const DASHBOARD_SET_ACTIVE_DASHBOARD = 'DASHBOARD_SET_ACTIVE_DASHBOARD'
export const DASHBOARD_WIDGETS_FETCHED = 'DASHBOARD_WIDGETS_FETCHED'
export const DASHBOARD_WIDGET_FETCHED = 'DASHBOARD_WIDGET_FETCHED'
export const DASHBOARD_WIDGET_DATA_FETCHED = 'DASHBOARD_WIDGET_DATA_FETCHED'
export const DASHBOARD_SHOW_FILTERS = 'DASHBOARD_SHOW_FILTERS'
export const DASHBOARD_SHOW_TIME_PERIOD_DIALOG = 'DASHBOARD_SHOW_TIME_PERIOD_DIALOG'

export const fetchDashboards = (dashboardid = 0, callback) => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        const entityToGet = 'dashboard' + (dashboardid === 0 ? "" : "/" + dashboardid);
        //const url = URLForKPIResource(entityToGet, true) + entityToGet;
        const url2 = CONFIG.restService.kpiUrl + "fullgraph/kpi/dashboard/" + dashboardid;
        new RestClient(dispatch).url(url2).token(token)
            .doGet(data => {
                if (data != null) {
                    if (dashboardid !== 0) {
                        data = [data];
                    }
                    dispatch(dashboardsFetched(data))
                }
                callback && callback();
            });
    }
}

export const dashboardsFetched = (dashboards) => {
    let allDashboards = dashboards.sort((d1, d2) => d1.dashboardid - d2.dashboardid);
    return {
        type: DASHBOARD_FETCHED,
        dashboards: allDashboards,
        dashboardsToShow: allDashboards.filter(dashboard => dashboard.active)
    }
}

export const fetchWidgets = (dashboardId) => {
    console.log("Fecth widgtes "+dashboardId)
    return (dispatch, getState) => {
        let token = getState().auth.token;
        const location = 'dashboard/' + dashboardId + "/widget";
        const url = URLForKPIResource(null, true) + location;
        let restClient = new RestClient(dispatch).token(token).url(url);
        restClient.doGet((response) => {
            if (response) {
                const widgets = response;
                let widgetsDictionary = {};
                widgets.forEach(widget => {
                    widgetsDictionary[widget.widgetid] = widget;
                })
                dispatch(widgetsFetched(dashboardId, widgetsDictionary))
            }
        });
    }
}

export const widgetsFetched = (dashboardId, widgets) => {
    return {
        type: DASHBOARD_WIDGETS_FETCHED,
        dashboardId: dashboardId,
        widgets: widgets
    }
}

export const fetchWidget = (widgetId) => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        let restClient = new RestClient(dispatch)
            .url(URLForKPIResource(null, true) + 'widget/' + widgetId).token(token);
        restClient.doGet((response) => {
            if (response) {
                const widget = response;
                dispatch(widgetFetched(widget))
            }
        });
    }
}

export const widgetFetched = (widget) => {
    return {
        type: DASHBOARD_WIDGET_FETCHED,
        widget: widget
    }
}

export const fetchWidgetData = (widgetId, callback) => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        const restClient = new RestClient(dispatch, false);
        restClient.url(CONFIG.restService.kpiUrl + 'completegraph/kpi/chart-data/' + widgetId).token(token)
            .doGet(data => {
                dispatch(widgetDataFetched(widgetId, data));
                callback && callback();
            });
    }
}

export const widgetDataFetched = (widgetId, data) => {
    return {
        type: DASHBOARD_WIDGET_DATA_FETCHED,
        widgetId: widgetId,
        data: data
    }
}

export const fetchTimePeriods = () => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        new RestClient(dispatch).token(token)
            .url(URLForKPIResource() + "timeperiod").doGet((response) => {
            if (response) {
                dispatch(timePeriodsFetched(response))
            }
        })
    }
}

export const timePeriodsFetched = (timePeriods) => {
    return {
        type: DASHBOARD_TIMEPERIODS_FETCHED,
        timePeriods: timePeriods
    }
}

export const showFilters = (visible) => {
    return {
        type: DASHBOARD_SHOW_FILTERS,
        visible: visible
    }
}

export const showTimePeriod = (visible) => {
    return {
        type: DASHBOARD_SHOW_TIME_PERIOD_DIALOG,
        visible: visible
    }
}

export const newDashboard = (dashboardTitle) => {
    return (dispatch, getState) => {
        const newDashboard = {title: dashboardTitle}
        let token = getState().auth.token;
        const restClient = new RestClient(dispatch);
        restClient.token(token).url(URLForKPIResource() + "dashboard")
            .doPost(newDashboard, (response) => {
                    if (restClient.isSuccess()) {
                        dispatch(fetchDashboards())
                    }
                }
            )
    }
}

export const deleteDashboard = (dashboardId) => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        const restClient = new RestClient(dispatch);
        restClient.token(token).url(URLForKPIResource() + "dashboard/" + dashboardId)
            .doDelete((response) => {
                    if (restClient.isSuccess()) {
                        dispatch(fetchDashboards())
                    }
                }
            )
    }
}
